<template>
    <div v-if="windowWidth>=768">
        <div class="flex justify-between items-center">
            <div class="w-full py-2 text-base whitespace-no-wrap grid">
                <span>{{ item.cn }}</span>
                <span>{{ item.name }}</span>
            </div>
            <div class="w-full py-2 flex justify-end items-center space-x-2">
                <span class="text-sm" style="color: rgb(75 85 99);">{{ item.time }}</span>
                <span :style="{color: (item.color=='true') ? 'red': ''}">{{ (item.value=='' || item.value==null) ? '-' : item.value }}</span>
                <slot name="button"></slot>
            </div>
        </div>
        <vs-divider class="my-0"></vs-divider>
    </div>
    <div v-else-if="windowWidth<768">
        <div class="flex  divide-y">
            <div class="w-full py-2 text-base whitespace-no-wrap grid">
                <span>{{ item.cn }}</span>
                <span>{{ item.name }}</span>
            </div>
            <div class="w-full py-2 flex justify-end items-center">
                <span :style="{color: (item.color=='true') ? 'red': ''}">{{ (item.value=='' || item.value==null) ? '-' : item.value }}</span>
            </div>
        </div>
        <div class="flex justify-between items-center">
            <span class="text-sm" style="color: rgb(75 85 99);">{{ item.time }}</span>
            <span class="flex space-x-2">
                <slot name="button"></slot>
            </span>
        </div>
        <vs-divider class=""></vs-divider>
    </div>
    <h2 v-else class="mt-5 flex center">{{$t('nodata')}}</h2>
</template>

<script>
export default{
    props:{
        item: {
            type: Object,
            required: false,
            default: '',
        },
    },
    data(){
        return {

            signValue: 0,
        }
    },
    computed:{
        windowWidth() {
            return this.$store.state.windowWidth
        },
    },
    methods:{
    }
}
</script>